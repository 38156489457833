<template>
  <div>
    <div class="goodsdetail">
      <particulars ref="detail"></particulars>
      <!-- <div
        class="orderNumber"
        v-if="gruopData.goodsStatus !== 2 && gruopData.goodsStatus !== 3"
      >
        <div class="order">
          <el-button
            type="primary"
            class="join"
            v-if="enterpriseStatus == 2"
            @click="primaryClick"
            >参与竞拍</el-button
          >
          <div v-if="enterpriseStatus == 4">
            <el-button type="primary" class="join" @click="unloaderClick"
              >参与竞拍</el-button
            >
          </div>

          <el-button
            type="primary"
            class="join"
            @click="auctionClick"
            v-if="enterpriseStatus != 2 && enterpriseStatus != 4"
            >参与竞拍</el-button
          >
          <el-button
            class="collect"
            icon="el-icon-plus"
            @click="follow"
            v-if="gruopData.ifCollect == 0"
            >收藏商品</el-button
          >
          <el-button
            class="collect"
            icon="el-icon-plus"
            @click="follows"
            v-if="gruopData.ifCollect == 1"
            >取消收藏</el-button
          >
        </div>
      </div> -->
      <div v-if="data.distanceStartTime && data.distanceStartTime != '0'">
        <div
          style="
            margin-top: 10px;
            padding: 10px 10px 20px 10px;
            margin-bottom: 20px;
            background-color: #f7f7f7;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              margin-bottom: 9px;
              font-size: 16px;
              color: #333;
            "
          >
            <div
              style="
                width: 53px;
                height: 1px;
                background: #2a8de9;
                display: inline-block;
              "
            ></div>
            <div style="margin: 0 5px">距离开始还有</div>

            <div
              style="
                width: 53px;
                height: 1px;
                background: #2a8de9;
                display: inline-block;
              "
            ></div>
          </div>
          <CountDown
            v-if="data.distanceStartTime"
            :endDate="`${data.distanceStartTime}`"
          ></CountDown>
        </div>
      </div>

      <div v-if="data.residueTime && data.residueTime != '0'">
        <div
          style="
            margin-top: 10px;
            padding: 10px 10px 20px 10px;
            margin-bottom: 20px;
            background-color: #f7f7f7;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              margin-bottom: 9px;
              font-size: 16px;
              color: #333;
            "
          >
            <div
              style="
                width: 53px;
                height: 1px;
                background: #2a8de9;
                display: inline-block;
              "
            ></div>
            <div style="margin: 0 5px">距离结束还有</div>

            <div
              style="
                width: 53px;
                height: 1px;
                background: #2a8de9;
                display: inline-block;
              "
            ></div>
          </div>
          <CountDown
            v-if="data.residueTime"
            :endDate="`${data.residueTime}`"
          ></CountDown>
        </div>
      </div>
      <div class="img_all" v-if="data?.lineType != 1">
        <!-- <div>竞价资料:{{ enterMessge }}</div>
        <div v-for="(item, index) in fileInfos" :key="index" class="flex_item">
          <div class="origin_item" @click="ItemClick(item)">
            {{ item.originalName }}
          </div>
        </div> -->
        <div
          class="company_list"
          style="display: flex; justify-content: space-between"
        >
          <div>参与公司</div>
          <div style="cursor: pointer" @click="flushClicks">
            <i class="el-icon-refresh"></i>
          </div>
        </div>
        <el-table :data="fileInfos" border style="width: 100%">
          <el-table-column
            prop="enterpriseName"
            label="公司名称"
            align="center"
          >
          </el-table-column>
          <el-table-column label="竞价资料" align="center">
            <template slot-scope="scope">
              <div>
                <el-button
                  @click="handleClick(scope.row.fileInfos)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="提交时间"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.publishAuditStatus == 0">
                <el-button @click="Approved(scope.row)" type="text" size="small"
                  >审核通过</el-button
                >
                <el-button
                  @click="refuseClick(scope.row)"
                  type="text"
                  size="small"
                  >审核拒绝</el-button
                >
              </div>
              <div v-if="scope.row.publishAuditStatus == 1">已通过</div>
              <div v-if="scope.row.publishAuditStatus == 2">审核拒绝</div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="company" v-if="computedLoginAndAuthentication">
        <div
          class="company_list"
          style="display: flex; justify-content: space-between"
        >
          <div>竞价公司</div> 
          <div style="cursor: pointer" @click="flushClick">
            <i class="el-icon-refresh"></i>
          </div>
        </div>
        <div class="company_table">
          <el-table :data="tableData" border>
            <el-table-column prop="companyName" align="center" label="竞价公司">
            </el-table-column>
            <el-table-column prop="offerPrice" align="center" label="报价金额">
              <template slot-scope="scope">
                {{
                  scope.row.offerDetailList[0].offerPrice == 0
                    ? "*"
                    : scope.row.offerDetailList[0].offerPrice
                }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="offerCount"
              align="center"
              label="报价数量(吨)"
            >
            </el-table-column> -->
            <el-table-column prop="createTime" align="center" label="报价时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | formatCreateTime }}
              </template>
            </el-table-column>
            <el-table-column prop="winBidding" align="center" label="中标状态">
              <template slot-scope="scope">
                <div v-if="scope.row.winBidding == 1">
                  <img src="../../assets/zxx_img/zhongbiao.png" alt="" />
                </div>
                <div v-if="scope.row.winBidding == 0">
                  <div>未中标</div>
                </div>
                <div v-if="scope.row.winBidding == '-1'">
                  <div>竞价中</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="details_bottom">
        <regulation class="regulation_bottom" :data="data"></regulation>
      </div>
      <!-- 
      <div class="success" v-if="gruopData.groupStatus === 2">
        <div style="margin-bottom: 10px">拼团成功</div>
        <div>
          前往订单信息进行合同签订及履约,<span
            @click="goContract"
            style="color: blue; cursor: pointer"
            >点击前往</span
          >
        </div>
      </div>
      <div class="success" v-if="gruopData.groupStatus === 3">
        <div>拼团失败</div>
      </div> -->
    </div>
    <InputPasswordDialog
      :isShow="passWordIsShows"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    />
    <el-dialog
      title="请选择支付方式"
      width="30%"
      :visible.sync="dialogofflineForm"
    >
      <el-form :model="offlineForm" ref="offlineForm" :rules="offlineFormRule">
        <el-form-item label="支付方式:" prop="bankType">
          <el-radio
            v-for="(item, index) in balances"
            :key="index"
            v-model="offlineForm.bankType"
            :label="item.bankType"
            :disabled="item.ifAsh"
          >
            <span v-if="item.bankType == 0"
              >中信账户余额(￥{{ item.balance }})</span
            >
            <span v-if="item.bankType == 1"
              >中金账户余额(￥{{ item.balance }})</span
            >
          </el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogofflineForm = false">取 消</el-button>
        <el-button type="primary" @click="flineFormClicks()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="参与竞拍资料上传"
      :visible.sync="dialogVisibleUpload"
      width="30%"
    >
      <div>
        <div style="margin: 10px 0">请上传相关资料</div>
        <el-upload
          class="upload-demo"
          action="#"
          :http-request="onSuccess"
          multiple
          :limit="10"
          :file-list="fileList"
          accept=".jpg, .jpeg, .png,.PDF"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            (可上传JPG/JPEG/PNG/PDF格式文件)
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleUpload = false">取 消</el-button>
        <el-button type="primary" @click="UploadClick">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="imgUrldialogVisible" width="30%">
      <div class="iamg_y">
        <!-- <img :src="imgUrl" alt="" /> -->
        <el-button
          @click="examinecontract(imgUrl)"
          v-if="
            (imgUrl && imgUrl.slice(-4) == '.pdf') ||
            (imgUrl && imgUrl.slice(-4) == '.PDF')
          "
        >
          点击查看PDF文件<br /><br /><br />{{ originalName }}</el-button
        >
        <el-image
          v-else
          style="width: 100px; height: 100px"
          :src="imgUrl"
          :preview-src-list="srcList"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgUrldialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="imgUrldialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="审核拒绝"
      :visible.sync="isRefuse"
      :modal="false"
      width="30%"
    >
      <el-form>
        <el-form-item label="拒绝理由">
          <el-input
            v-model="refuseText"
            type="textarea"
            maxlength="20"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isRefuse = false">取 消</el-button>
        <el-button type="primary" @click="sureRefuse">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CountDown from "@/components/countDowns.vue";
import particulars from "./particulars.vue";
import { groupDtetails, ossUploadFiletwo } from "@/api/public.js";
import {
  getEnterpriseStatus,
  uploadInformation,
  applyToBeService,
  getTbGoodsDetail,
  auditInformation,
  tradeOfferpage,
} from "@/api/Logistics.js";
import { formatDate } from "@/utils/date";
import { reqIsPayBonds, reqFollow, joinGroup } from "@/api/hy";
import * as types from "@/store/action-types";
import md5 from "js-md5";
import Vue from "vue";
import InputPasswordDialog from "./passwordInput.vue";
import { getAccountBalanceByParam } from "@/api/Accountopening";
// import regulation from "@/layout/regulation.vue";
import regulation from "../Logistics/components/regulation.vue";
const { baseUrl } = require("@/config");
import { mapState, mapActions } from "vuex";
export default {
  components: {
    particulars,
    CountDown,
    InputPasswordDialog,
    regulation,
  },
  name: "",
  data() {
    return {
      isRefuse: false,
      refuseText: "",
      retfuid: "",
      srcList: [],
      baseUrl: baseUrl,
      imgUrldialogVisible: false,
      imgUrl: "",
      fileInfos: [],
      type: 1,
      fileList: [],
      dialogVisibleUpload: false,
      enterpriseStatus: 0,
      enterMessge: "",
      data: {},
      offlineForm: {
        bankType: "",
      },
      balances: [],
      passWordIsShows: false,
      dialogofflineForm: false,
      templateSelection: "",
      dialogVisible: false,
      dialogVisible1: false,
      pageData: [],
      num: "",
      TbaleList: [],
      gruopData: [],
      tableData: [],
      allPice: 0,
      offlineFormRule: {
        bankType: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
      },
      fileL: [],
      storageName: "",
      imgUrl: "",
      srcList: [],
      originalName: "",
    };
  },
  mounted() {
    this.getlisst();
    this.getstatus();
    this.getTbGoodsDetail();
  },
  computed: {
    ...mapState({
      massage: (state) => state.user.massage,
    }),
    computedLoginAndAuthentication() {
      let status =
        this.massage == "未登录请前往登录" ||
        this.massage == "请前往认证后操作";
      return !status;
    },
  },

  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    winBidding(val) {
      switch (val) {
        case -1:
          return "竞拍中";
          break;
        case 0:
          return "未中标";
          break;
        case 1:
          return "已中标";
          break;
      }
    },
  },
  methods: {
    examinecontract(item) {
      window.open(item);
    },
    handleClick(e) {
      console.log(e);
      let a = e[0];
      this.imgUrldialogVisible = true;
      this.originalName = a.originalName;
      this.imgUrl = this.baseUrl + "third/third/ossGetFile/" + a.storageName;
      this.srcList.push(
        this.baseUrl + "third/third/ossGetFile/" + a.storageName
      );
    },
    flushClicks() {
      this.getTbGoodsDetail();
    },
    refuseClick(e) {
      this.isRefuse = true;
      this.retfuid = e.informationId;
    },
    sureRefuse() {
      let config = {
        auditReason: this.refuseText,
        auditStatus: 2,
        auditType: 2,
        informationId: this.retfuid,
      };
      auditInformation(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.isRefuse = false;
          this.getTbGoodsDetail();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    Approved(e) {
      this.$confirm("是否确认审核通过资料信息?", "审核通过", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let config = {
            auditStatus: 1,
            auditType: 2,
            informationId: e.informationId,
          };
          auditInformation(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.getTbGoodsDetail();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    ItemClick(e) {
      console.log(e);
      this.imgUrldialogVisible = true;
      this.imgUrl = this.baseUrl + "third/third/ossGetFile/" + e.storageName;
      this.srcList.push(
        this.baseUrl + "third/third/ossGetFile/" + e.storageName
      );
    },
    getTbGoodsDetail() {
      getTbGoodsDetail(this.$route.query.goodsId).then((res) => {
        if (res.code == 0) {
          // createTime
          let list = res.data.informationList;
          if (list) {
            for (let i = 0; i < list.length; i++) {
              if (list[i].createTime) {
                list[i].createTime = list[i].createTime.replace("T", " ");
              }
            }
          }
          this.data = res.data;
          console.log("this.data");
          console.log(this.data);
          this.fileInfos = list;
        }
      });
    },
    primaryClick() {
      this.dialogVisibleUpload = true;
      this.type = 2;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    UploadClick() {
      if (this.type == 1) {
        let config = {
          fileIds: this.fileL,
          goodsId: this.$route.query.goodsId,
        };
        uploadInformation(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.dialogVisibleUpload = false;
          }
        });
      } else if (this.type == 2) {
        let config = {
          roadTransportLicenseUrl: this.storageName,
        };
        applyToBeService(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.dialogVisibleUpload = false;
            this.getstatus();
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        });
      }
    },
    onSuccess(file) {
      // console.log(file,this.fileList)
      let otherFiles = file.file;
      var formData = new FormData();
      formData.append("file", otherFiles);
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          let list = [];
          this.storageName = res.data.storageName;
          list.push(res.data.fileId);
          var newArr = [...new Set(list)];
          this.fileL = newArr;
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.getstatus();
        }
      });
    },
    unloaderClick() {
      this.dialogVisibleUpload = true;
      this.type = 1;
    },
    // 参与竞拍（多重判断）
    auctionClick() {
      if (
        this.enterpriseStatus == 0 ||
        this.enterpriseStatus == 1 ||
        this.enterpriseStatus == 3 ||
        this.enterpriseStatus == 5
      ) {
        this.$message({
          message: this.enterMessge,
          type: "warning",
        });
      }
    },
    getstatus() {
      let id = this.$route.query.goodsId;
      getEnterpriseStatus(id).then((res) => {
        if (res.code == 0) {
          this.enterpriseStatus = res.data.enterpriseStatus;
          this.enterMessge = res.data.message;
        }
      });
    },
    flushClick() {
      this.getlisst();
    },
    //签合同
    goContract() {
      this.$router.push({
        path: "/order",
      });
    },
    btnClick() {
      let config = {
        searchType: 0,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balances = res.data;
          this.dialogofflineForm = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    flineFormClicks(e) {
      this.dialogofflineForm = false;
      let that = this;
      if (that.offlineForm.bankType == 0 || that.offlineForm.bankType == 1) {
        that.passWordIsShows = true;
      }
    },
    getreqIsPayBond() {
      this.btnClick();
    },
    submit(val) {
      console.log(val, "222222");
      let id = this.$route.query.goodsId;
      let config = {
        goodsId: id,
        groupPremiumId: this.templateSelection.groupPremiumId,
        payAmount: this.allPice,
        orderCount: this.num,
        tradePassword: md5(val),
        bankType: this.offlineForm.bankType,
      };
      joinGroup(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.data,
            type: "success",
          });
          this.$route.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeDialog(tag) {
      this[tag] = false;
      this.passWordIsShows = false;
    },
    biddingOffer() {},
    openPayBond() {
      this.dialogVisible1 = false;
      let id = Vue.ls.get(types.enterpriseId);
      reqIsPayBonds(id).then((ret) => {
        console.log(ret);
        if (ret.code == 0) {
          console.log(ret.data);
          if (ret.data) {
            this.getreqIsPayBond();
          } else {
            this.$confirm("暂无设置交易密码, 是前往设置?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/finance");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        } else {
          this.$message({
            type: "error",
            message: ret.message,
          });
        }
      });
    },
    toPay() {
      this.dialogVisible = false;
      this.dialogVisible1 = true;
    },
    radioChange(e) {
      let newNum =
        Number(this.num) *
        Number(this.gruopData.listingPrice) *
        (Number(this.templateSelection.payRatio) / 100);
      this.allPice = newNum;
    },
    getlisst() {
      // tradeOfferpage
      let config = {
        goodsId: this.$route.query.goodsId,
        page: 1,
        size: 9999,
      };
      tradeOfferpage(config).then((res) => {
        if (res.code == 0) {
          console.log(res, "====================");
          this.tableData = res.data.records;

          if (this.tableData.length) {
            this.tableData.forEach((item) => {
              item.createTime = item.createTime.replace(/T/g, " ");
            });
          }
          // this.pageData = res.data.groupPriceList;
          // this.TbaleList = res.data.groupEnterpriseList;
          // this.gruopData = res.data;
        }
      });
    },
    //收藏商品
    follow() {
      if (!this.beforhandle("personalCenter:auctionSale:partake:collect"))
        return;
      reqFollow({
        goodsId: this.$route.query.goodsId,
        type: 0,
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            // this.$refs.detail.getlisst(this.$route.query.goodsId);
            this.getlisst();
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取消收藏
    follows() {
      this.$confirm("是否要取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reqFollow({
            goodsId: this.$route.query.goodsId,
            type: 1,
          }).then((res) => {
            if (res.code == 0) {
              // this.$refs.detail.getlisst(this.$route.query.goodsId);
              this.getlisst();
              this.$message({
                type: "success",
                message: "取消收藏成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsdetail {
  width: 1200px;
  padding: 25px 20px;
  background: white;
  border: 1px solid #e5e5e5;
  margin: 15px auto;

  &::before,
  &::after {
    display: table;
    content: "";
  }
  .groupInformation {
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 25px 20px;
  }

  .titleInfo {
    font-size: 18px;
    font-weight: bold;
  }
  .tableMain {
    display: flex;
    flex-direction: row;
    .pirceTable {
      margin-top: 20px;
      width: 500px;

      .gear {
        display: flex;
        .pirce {
          font-size: 14px;
        }
      }
    }
    .border {
      width: 2px;
      background-color: #6666;
    }
    .tableRight {
      margin-left: 30px;
      margin-top: 20px;
      display: flex;
    }
  }

  .orderNumber {
    margin-top: 10px;
    border: 1px solid #ccc;
    .order {
      display: flex;
      padding: 10px 20px 10px 0;
      .join {
        margin-left: 10px;
        width: 150px;
        height: 50px;
        margin-top: -3px;
      }
      .collect {
        margin-left: 10px;
        width: 150px;
        height: 50px;
        margin-top: -3px;
      }
    }
  }
  .success {
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 20px;
  }
}
.company {
  margin: 20px 0;
}
.company_list {
  border: 1px solid #ccc;
  padding: 0 20px;
  font-size: 18px;
  background: #e5e5e5;
  height: 45px;
  line-height: 45px;
  color: #000;
}
.company_table {
  margin-top: 10px;
}
.details_bottom {
  margin-top: 20px;

  .regulation_bottom {
    // margin-left: 20px;
    width: 100%;
  }
}
.img_all {
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}
.flex_item {
  margin-top: 10px;
}
.origin_item {
  color: aqua;
  cursor: pointer;
}
.iamg_y {
  text-align: center;
  img {
    width: 400px;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/tag.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".item_images[data-v-568048e9]{width:5.208333rem;height:1.25rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:5.208333rem 100%;color:#fff;padding-left:.15625rem;padding-top:.104167rem;margin-top:.520833rem;display:flex}.particu_cont[data-v-568048e9]{display:flex;justify-content:space-between;align-items:center;border-top:.052083rem dashed #ccc;border-bottom:.052083rem dashed #ccc;margin-top:1.041667rem}.particu[data-v-568048e9]{padding:1.041667rem;border:.052083rem solid #ccc}.particu .particu_name[data-v-568048e9]{display:flex;justify-content:space-between}.particu .particu_name .title[data-v-568048e9]{font-size:.9375rem;font-weight:700}.particu .particu_name .number[data-v-568048e9]{margin-top:.260417rem}.particu .particu_items[data-v-568048e9]{display:flex;align-content:center;padding:1.041667rem 0}.particu .particu_item[data-v-568048e9]{display:flex;padding:1.041667rem 0;border-top:.052083rem dashed #ccc;border-bottom:.052083rem dashed #ccc;margin-top:1.041667rem}.particu .auction[data-v-568048e9],.particu .auction .auction_item[data-v-568048e9],.particu .auction .auction_item .finish[data-v-568048e9],.particu .delivery[data-v-568048e9],.particu .indicator[data-v-568048e9],.particu .relation[data-v-568048e9]{margin-top:1.041667rem}.particu .indicator[data-v-568048e9]{display:flex}.particu .indicator .tables[data-v-568048e9]{text-align:center}.particu .indicator .tables table[data-v-568048e9]{border:none}.particu .indicator .tables .tables_tr[data-v-568048e9]{flex-wrap:wrap;font-weight:700}.particu .indicator .tables td[data-v-568048e9]{border:none;width:11.458333rem;padding:.260417rem .520833rem;border:.052083rem dashed #000}.particu .remark[data-v-568048e9]{margin-top:1.041667rem;display:flex}.bto_bor[data-v-568048e9]{border-bottom:.052083rem solid #ccc;padding-bottom:.520833rem}", ""]);
// Exports
module.exports = exports;
